const serviceData = [
  {
    id: 1,
    title: "Worldwide Cheap Flights",
    icon: "ri-map-pin-2-line",
    //desc: "Tempor tempor sadipscing vero lorem sea, invidunt sed et eos ipsum et erat. Dolor ut duo sadipscing lorem. Gubergren gub",
  },

  {
    id: 2,
    title: "Cheap Fares Guaranteed",
    icon: "ri-community-line",
    //desc: "Tempor tempor sadipscing vero lorem sea, invidunt sed et eos ipsum et erat. Dolor ut duo sadipscing lorem. Gubergren gub",
  },

  {
    id: 3,
    title: "Best Customer Services",
    icon: "ri-roadster-line",
    //desc: "Tempor tempor sadipscing vero lorem sea, invidunt sed et eos ipsum et erat. Dolor ut duo sadipscing lorem. Gubergren gub",
  },

  {
    id: 4,
    title: "Flexible Payment Plans",
    icon: "ri-timer-flash-line",
    //desc: "Tempor tempor sadipscing vero lorem sea, invidunt sed et eos ipsum et erat. Dolor ut duo sadipscing lorem. Gubergren gub",
  },

  {
    id: 5,
    title: "Financial Protection",
    icon: "ri-map-pin-line",
   // desc: "Tempor tempor sadipscing vero lorem sea, invidunt sed et eos ipsum et erat. Dolor ut duo sadipscing lorem. Gubergren gub",
  },

  {
    id: 6,
    title: "Personalized Flight Deals",
    icon: "ri-flight-takeoff-line",
    //desc: "Tempor tempor sadipscing vero lorem sea, invidunt sed et eos ipsum et erat. Dolor ut duo sadipscing lorem. Gubergren gub",
  },
];

export default serviceData;
