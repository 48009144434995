import React from 'react'
import '../../styles/pop-up.css'
import { Link } from 'react-router-dom';

const PopUp = ({ isOpen, onClose }) => {

    if (!isOpen) return null;

    const phoneNumber = '0203 376 5552'

    const makePhoneCall = () => {
        window.location.href = `tel:${phoneNumber}`;
    }


  return (
    <div className="popup-overlay" onClick={onClose}>
      <div className="popup-content" onClick={(e) => e.stopPropagation()}>
        <span className="close-button" onClick={onClose}>&times;</span>
        <div className='modal__header'>
            <h2>Let Us Help You Book <br /> Special Deals <i class="ri-customer-service-2-fill"></i></h2>
        </div>
        
        <div className='modal__body'>
            <p>Get Up to 25% Off 🎉<br /> On All Flights</p>
            <p className='phone' onClick={makePhoneCall}>
                <i class="ri-phone-fill"></i>
                <Link className='' ><span> 0203 376 5552</span></Link>
            </p>
        </div>

        <div className='call-us text-center' onClick={makePhoneCall}>
            <Link className='modal-call-btn'>Call Us Now</Link>
        </div>

        <div className='modal__footer'>

        </div>

      </div>
    </div>
  )
}

export default PopUp