const airportData = [
    'Aarhus (AAR)', 'Abadan (ABD)', 'Abeche (AEH)', 'Aberdeen (ABZ)', 'Aberdeen (SD) (ABR)', 
    'Abidjan (ABJ)', 'Abilene (TX) (ABI)', 'Abu Dhabi (AUH)', 'Abuja (ABV)', 'Abu Rudeis (AUE)', 
    'Abu Simbel (ABS)', 'Acapulco (ACA)', 'Accra (ACC)', 'Adana (ADA)', 'Addis Ababa (ADD)', 
    'Adelaide (ADL)', 'Aden (ADE)', 'Adiyaman (ADF)', 'Adler/Sochi (AER)', 'Agades (AJY)', 
    'Agadir (AGA)', 'Agana (SUM)', 'Aggeneys (AGZ)', 'Aguadilla (BQN)', 'Aguascaliente (AGU)', 
    'Ahmedabad (AMD)', 'Aiyura (AYU)', 'Ajaccio (AJA)', 'Akita (AXT)', 'Akron (OH) (CAK)', 
    'Akrotiri (AKT)', 'Al Ain (AAN)', 'Al Arish (AAC)', 'Albany (ALH)', 'Albany (GA) (ABY)', 
    'Albany (NY) (ALB)', 'Albi (LBI)', 'Alborg (AAL)', 'Albuquerque (NM) (ABQ)', 'Albury (ABX)', 
    'Alderney (ACI)', 'Aleppo (ALP)', 'Alesund (AES)', 'Alexander Bay (ALJ)', 'Alexandria (HBH)', 
    'Alexandria (ALY)', 'Alexandria (ESF)', 'Alfujairah (FJR)', 'Alghero Sassari (AHO)', 
    'Algiers (ALG)', 'Al Hoceima (AHU)', 'Alicante (ALC)', 'Alice Springs (ASP)', 'Alldays (ADY)', 
    'Allentown (PA) (ABE)', 'Almaty (ALA)', 'Almeria (LEI)', 'Alta (ALF)', 'Altay (AAT)', 
    'Altenrhein (ACH)', 'Altoona (PA) (AOO)', 'Altus (AXS)', 'Amami (ASJ)', 'Amarillo (TX) (AMA)', 
    'Amazon Bay (AZB)', 'Amman (AMM)', 'Amman (ADJ)', 'Amritsar (ATQ)', 'Amsterdam (AMS)', 
    'Anand (QNB)', 'Anchorage (ANC)', 'Ancona (AOI)', 'Andorra La Vella (ALV)', 'Anguilla (AXA)', 
    'Anjouan (AJN)', 'Ankara (ANK)', 'Ankara (ESB)', 'Annaba (AAE)', 'Ann Arbor (MI) (ARB)', 
    'Annecy (NCY)', 'Anniston (AL) (ANB)', 'Antalya (AYT)', 'Antananarivo (TNR)', 'Antigua (ANU)', 
    'Antwerp (ANR)', 'Aomori (AOJ)', 'Apia (APW)', 'Appelton (ATW)', 'Aqaba (AQJ)', 'Aracaju (AJU)', 
    'Arkhangelsk (ARH)', 'Arusha (ARK)', 'Araxos (GPA)', 'Arlit (RLT)', 'Arrecife (ACE)', 
    'Aruba (AUA)', 'Asheville (AVL)', 'Ashgabat (ASB)', 'Asmara (ASM)','Aspen (ASE)', 'Assiut (ATZ)', 'Astana (TSE)', 'Asuncion (ASU)', 'Aswan (ASW)', 
    'Athens (ATH)', 'Athens (HEW)', 'Athens (GA) (AHN)', 'Athens (OH) (ATO)', 
    'Atlanta (ATL)', 'Atlantic City (ACY)', 'Attawapiskat (YAT)', 'Auckland (AKL)', 
    'Augsburg (AGB)', 'Augusta (GA) (AGS)', 'Augusta (ME) (AUG)', 'Aurillac (AUR)', 
    'Austin (AUS)', 'Ayawasi (AYW)', 'Ayers Rock (AYQ)', 'Ayr (AYR)', 'Badajoz (BJZ)', 
    'Bagdad (BGW)', 'Bagdogra (IXB)', 'Bahamas (NAS)', 'Bahawalpur (BHV)', 'Bahrain (BAH)', 
    'Bakersfield (BFL)', 'Baku (BAK)', 'Ballina (BNK)', 'Baltimore (BWI)', 'Bamaga (ABM)', 
    'Bamako (BKO)', 'Bambari (BBY)', 'Bandar Seri Begawan (BWN)', 'Bandung (BDO)', 
    'Bangalore (BLR)', 'Bangassou (BGU)', 'Bangkok (DMK)', 'Bangkok (BKK)', 'Bangor (BGR)', 
    'Bangui (BGF)', 'Banjul (BJL)', 'Bannu (BNP)', 'Barcelona (BCN)', 'Barcelona (BLA)', 
    'Bardufoss (BDU)', 'Bari (BRI)', 'Barisal (BZL)', 'Baroda (BDQ)', 'Barra (BRR)', 
    'Barranquilla (BAQ)', 'Basel (BSL)', 'Basel/Mulhouse (EAP)', 'Basra (BSR)', 
    'Basse-Terre (PTP)', 'Basseterre (SKB)', 'Bastia (BIA)', 'Baton Rouge (BTR)', 
    'Bayreuth (BYU)', 'Beaumont (BPT)', 'Beckley (BKW)', 'Beef Island (EIS)', 'Beijing (PEK)', 
    'Beijing (NAY)', 'Beira (BEW)', 'Beirut (BEY)', 'Belem (BEL)', 'Belfast (BHD)', 
    'Belfast (BFS)', 'Belgaum (IXG)', 'Belgrad (BEG)', 'Belize City (BZE)', 'Bellingham (BLI)', 
    'Belo Horizonte (CNF)', 'Bemidji (BJI)', 'Benbecula (BEB)', 'Benghazi (BEN)', 
    'Benguela (BUG)', 'Benton Harbour (BEH)', 'Berberati (BBT)', 'Bergamo (BGY)', 
    'Bergen (BGO)', 'Bergerac (EGC)', 'Berlin (BER)', 'Berlin (SXF)', 'Berlin (TXL)', 
    'Berlin (THF)', 'Bermuda (BDA)', 'Berne (BRN)', 'Berne (ZDJ)', 'Bethel (BET)', 
    'Bhopal (BHO)', 'Bhubaneswar (BBI)', 'Biarritz (BIQ)', 'Bilbao (BIO)', 'Billings (BIL)', 
    'Billund (BLL)', 'Bintulu (BTU)', 'Biraro (IRO)','Birmingham (BHX)', 'Birmingham (AL) (BHM)', 'Bishkek (FRU)', 'Bismarck (ND) (BIS)', 'Bissau (BXO)', 
    'Blackpool (BLK)', 'Blackwater (BLT)', 'Blantyre (Mandala) (BLZ)', 'Blenheim (BHE)', 'Bloemfontein (BFN)', 
    'Bloomington (IL) (BMI)', 'Bloomington (IN) (BMG)', 'Bluefield (WV) (BLF)', 'Boa Vista (BVB)', 
    'Bobo/Dioulasso (BOY)', 'Bodo (BOO)', 'Bodrum (BJV)', 'Bogota (BOG)', 'Boise (ID) (BOI)', 
    'Bologna (BLQ)', 'Bombay (Mumbai) (BOM)', 'Bonaire (BON)', 'Bonaventure, PQ (YVB)', 'Bora Bora (BOB)', 
    'Bordeaux (BOD)', 'Borrego Springs (CA) (BXS)', 'Boston (MA) (BOS)', 'Bouake (BYK)', 'Bourgas/Burgas (BOJ)', 
    'Bournemouth (BOH)', 'Bowen (ZBO)', 'Bozeman (MT) (BZN)', 'Bradford/Warren (PA) /Olean (NY) (BFD)', 
    'Brainerd (MN) (BRD)', 'Brampton Island (BMP)', 'Brasilia (BSB)', 'Bratislava (BTS)', 'Brazzaville (BZV)', 
    'Bremen (BRE)', 'Brescia, Montichiari (VBS)', 'Brest (BES)', 'Bria (BIV)', 'Bridgeport (CT) (BDR)', 
    'Bridgetown (BGI)', 'Brindisi (BDS)', 'Brisbane (BNE)', 'Bristol (BRS)', 'Broennoeysund (BNN)', 
    'Broken Hill (BHQ)', 'Brookings (SD) (BKX)', 'Broome (BME)', 'Brunswick (GA) (BQK)', 'Brussels (BRU)', 
    'Bucaramanga (BGA)', 'Bucharest (BUH)', 'Bucharest - Henri Coandă (OTP)', 'Budapest (BUD)', 
    'Buenos Aires (BUE)', 'Buenos Aires, Ezeiza (EZE)', 'Buenos Aires, Jorge Newbery (AEP)', 
    'Buffalo Range (BFO)', 'Buffalo/Niagara Falls (NY) (BUF)', 'Bujumbura (BJM)', 'Bulawayo (BUQ)', 
    'Bullhead City (NV) (BHC)', 'Bundaberg (BDB)', 'Burbank (CA) (BUR)', 'Burlington IA (BRL)', 
    'Burlington (VT) (BTV)', 'Burnie (Wynyard) (BWT)', 'Butte (MT) (BTM)', 'Cabinda (CAB)', 
    'Cagliari (CAG)', 'Cairns (CNS)', 'Cairo (CAI)', 'Calama (CJC)', 'Calcutta (Kolkata) (CCU)', 
    'Calgary (YYC)', 'Cali (CLO)', 'Calicut (CCJ)', 'Calvi (CLY)', 'Cambridge Bay (YCB)', 
    'Cambridge (CBG)', 'Campbeltown (CAL)', 'Campo Grande (CGR)', 'Canberra (CBR)', 'Cancun (CUN)', 
    'Cannes (CEQ)', 'Canouan (CIW)', 'Cape Town (CPT)', 'Caracas (CCS)', 'Cardiff (CWL)', 
    'Carlsbad (CA) (CLD)', 'Carnarvon (CVQ)', 'Carnot (CRF)', 'Carson City (NV) (CSN)', 
    'Casablanca (CAS)', 'Casablanca, Mohamed V (CMN)', 'Casa de Campo (LRM)', 'Casino (CSI)',
    'Casper (CPR)', 'Castaway (CST)', 'Cartagena (CTG)', 'Castries (SLU)', 'Catania (CTA)', 
    'Cayenne (CAY)', 'Cottbus (CBU)', 'Cebu City (CEB)', 'Cedar City (CDC)', 'Cedar Rapids (CID)', 
    'Ceduna (CED)', 'Cessnock (CES)', 'Chabarovsk (KHV)', 'Chambery (CMF)', 'Champaign (CMI)', 
    'Chandigarh (IXC)', 'Changchun (CGQ)', 'Chania (CHQ)', 'Chaoyang (CHG)', 'Charleston (CHS)', 
    'Charleston (CRW)', 'Charlotte (CLT)', 'Charlottesville (CHO)', 'Charters Towers (CXT)', 
    'Chattanooga (CHA)', 'Chengdu (CTU)', 'Chennai (MAA)', 'Cheyenne (CYS)', 'Chiang Mai (CNX)', 
    'Chiba City (QCB)', 'Chicago, Midway (MDW)', 'Chicago, O','Hare (ORD)', 'Chicago (CHI)', 
    'Chichen Itza (CZA)', 'Chico (CIC)', 'Chihuahua (CUU)', 'Chios (JKH)', 'Chipata (CIP)', 
    'Chisinau (KIV)', 'Chita (HTA)', 'Sapporo (CTS)', 'Chitral (CJL)', 'Chittagong (CGP)', 
    'Chongqing (CKG)', 'Christchurch (CHC)', 'Chub Cay (CCZ)', 'Churchill (YYQ)', 'Cienfuegos (CFG)', 
    'Cincinnati (CVG)', 'Ciudad Del Carmen (CME)', 'Ciudad Guayana (CGU)', 'Ciudad Juarez (CJS)', 
    'Ciudad Obregon (CEN)', 'Ciudad Victoria (CVM)', 'Clarksburg (CKB)', 'Clermont (CMQ)', 
    'Clermont Ferrand (CFE)', 'Cleveland, Burke Lakefront (BKL)', 'Cleveland, Hopkins (CLE)', 
    'Cochabamba (CBB)', 'Cochin (COK)', 'Cody (COD)', 'Coffmann Cove (KCC)', 'Coffs Harbour (CFS)', 
    'Coimbatore (CJB)', 'Colima (CLQ)', 'College Station (CLL)', 'Collinsville (KCE)', 
    'Cologne (CGN)', 'Colombo (CMB)', 'Colorado Springs (COS)', 'Columbia (CAE)', 
    'Columbus (CSG)', 'Columbus, Port Columbus (CMH)', 'Conakry (CKY)', 'Concord, Buchanan Field (CCR)', 
    'Concord, Municipal (CON)', 'Constantine (CZL)', 'Constanta (CND)', 'Coober Pedy (CPD)', 
    'Cooktown (CTN)', 'Cooma (OOM)', 'Copenhagen (CPH)', 'Cordoba (COR)', 'Cordoba (ODB)', 
    'Cordova (CDV)', 'Corfu (CFU)', 'Cork (ORK)', 'Corpus Christi (CRP)', 'Cotonou (COO)', 
    'Coventry (CVT)', 'Cozmel (CZM)', 'Craig (CGA)', 'Crescent City (CEC)', 'Cuiaba (CGB)', 
    'Culiacan (CUL)', 'Curacao (CUR)', 'Curitiba (CWB)', 'Cuyo (CYU)', 'Dakar (DKR)', 'Dalaman (DLM)', 'Dalby (DBY)', 'Dalian (DLC)', 'Dallas (DAL)', 'Dallas/Ft. Worth (DFW)', 
    'Daloa (DJO)', 'Damascus (DAM)', 'Dammam (DMM)', 'Danville (DAN)', 'Dar es Salam (DAR)', 
    'Darwin (DRW)', 'Daydream Island (DDI)', 'Dayton (DAY)', 'Daytona Beach (DAB)', 'Decatur (DEC)', 
    'Deer Lake (YDF)', 'Delhi (DEL)', 'Den Haag (HAG)', 'Denizli (DNZ)', 'Denpasar/Bali (DPS)', 
    'Denver (DEN)', 'Dera Ismail Khan (DSK)', 'Derby (DRB)', 'Derry (LDY)', 'Des Moines (DSM)', 
    'Detroit (DET)', 'Detroit (DTW)', 'Detroit (DTT)', 'Devils Lake (DVL)', 'Devonport (DPO)', 
    'Dhahran (DHA)', 'Dhaka (DAC)', 'Dili (DIL)', 'Dillingham (DLG)', 'Dinard (DNR)', 
    'Disneyland Paris (DLP)', 'Djerba (DJE)', 'Djibouti (JIB)', 'Dodoma (DOD)', 'Doha (DOH)', 
    'Doncaster/Sheffield (DSA)', 'Donegal (CFN)', 'Dortmund (DTM)', 'Dothan (DHN)', 'Douala (DLA)', 
    'Dresden (DRS)', 'Dubai (DXB)', 'Dubbo (DBO)', 'Dublin (DUB)', 'Dubois (DUJ)', 
    'Dubrovnik (DBV)', 'Dubuque (DBQ)', 'Duesseldorf (DUS)', 'Duluth (DLH)', 'Dundee (DND)', 
    'Dunedin (DUD)', 'Dunk Island (DKI)', 'Durango (DRO)', 'Durban (DUR)', 'Dushanbe (DYU)', 
    'Dutch Harbor (DUT)', 'Dysart (DYA)', 'Dzaoudzi (DZA)', 'East London (ELS)', 'Easter Island (IPC)', 
    'Eau Clarie (EAU)', 'Edinburgh (EDI)', 'Edmonton (YEA)', 'Edmonton (YEG)', 'Edmonton (YXD)', 
    'Egilsstadir (EGS)', 'Eindhoven (EIN)', 'Samana (AZS)', 'Elba Island (EBA)', 'Elat (ETH)', 
    'Elat (VDA)', 'Elkhart (EKI)', 'Elko (EKO)', 'Ellisras (ELL)', 'El Minya (EMY)', 
    'Elmira (ELM)', 'El Paso (ELP)', 'Ely (ELY)', 'Emerald (EDR)', 'Emerald (EMD)', 
    'Enontekioe (ENF)', 'Entebbe (EBB)', 'Erfurt (ERF)', 'Erie (ERI)', 'Eriwan (EVN)', 
    'Erzincan (ERC)', 'Erzurum (ERZ)', 'Esbjerg (EBJ)', 'Escanaba (ESC)', 'Esperance (EPR)', 
    'Eugene (EUG)', 'Eureka (ACV)', 'Evansville (EVV)', 'Evenes (EVE)','Exeter (EXT)', 'Fairbanks (FAI)', 'Fair Isle (FIE)', 'Faisalabad (LYP)', 'Fargo (FAR)', 
    'Farmington (FMN)', 'Faro (FAO)', 'Faroer (FAE)', 'Fayetteville (FYV)', 'Fayetteville/Ft. Bragg (FAY)', 
    'Fes (FEZ)', 'Figari (FSC)', 'Flagstaff (FLG)', 'Flin Flon (YFO)', 'Flint (FNT)', 
    'Florence (FLR)', 'Florence (FLO)', 'Florianopolis (FLN)', 'Floro (FRO)', 'Fort Albert (YFA)', 
    'Fortaleza (FOR)', 'Fort de France (FDF)', 'Fort Dodge (FOD)', 'Fort Huachuca/Sierra Vista (FHU)', 
    'Fort Lauderdale/Hollywood (FLL)', 'Fort McMurray (YMM)', 'Fort Myers (FMY)', 'Fort Myers (RSW)', 
    'Fort Riley (FRI)', 'Fort Smith (YSM)', 'Fort Smith (FSM)', 'Fort St. John (YXJ)', 
    'Fort Walton Beach (VPS)', 'Fort Wayne (FWA)', 'Fort Worth (DFW)', 'Foula (FOU)', 
    'Francistown (FRW)', 'Frankfurt/Main (FRA)', 'Frankfurt/Hahn (HHN)', 'Franklin/Oil City (FKL)', 
    'Fredericton (YFC)', 'Freeport (FPO)', 'Freetown (FNA)', 'Frejus (FRJ)', 'Fresno (FAT)', 
    'Friedrichshafen (FDH)', 'Fuerteventura (FUE)', 'Fujairah (FJR)', 'Fukuoka (FUK)', 'Fukushima (FKS)', 
    'Funchal (FNC)', 'Futuna (FUT)', 'Gaborone (GBE)', 'Gadsden (GAD)', 'Gainesville (GNV)', 
    'Galway (GWY)', 'Gander (YQX)', 'Garoua (GOU)', 'Gaza City (GZA)', 'Gaziantep (GZT)', 
    'Gdansk (GDN)', 'Geelong (GEX)', 'Geneva (GVA)', 'Genoa (GOA)', 'George (GRJ)', 
    'Georgetown (GEO)', 'Geraldton (GET)', 'Gerona (GRO)', 'Ghent (GNE)', 'Gibraltar (GIB)', 
    'Gilette (GCC)', 'Gilgit (GIL)', 'Gillam (YGX)', 'Gladstone (GLT)', 'Glasgow, Prestwick (PIK)', 
    'Glasgow (GLA)', 'Glasgow (GGW)', 'Glendive (GDV)', 'Goa (GOI)', 'Goiania (GYN)', 
    'Gold Coast (OOL)', 'Goondiwindi (GOO)', 'Goose Bay (YYR)', 'Gorna (GOZ)', 'Gothenburg (GOT)', 
    'Gove (GOV)', 'Govenors Harbour (GHB)', 'Granada (GRX)', 'Grand Bahama (FPO)', 'Grand Canyon (GCN)', 
    'Grand Cayman (GCM)', 'Grand Forks (GFK)', 'Grand Junction (GJT)', 'Grand Rapids (GRR)', 
    'Grand Rapids (GPZ)', 'Graz (GRZ)', 'Great Falls (GTF)', 'Great Keppel Island (GKL)', 
    'Green Bay (GRB)', 'Greenbrier/Lewisburg (LWB)', 'Greensboro/Winston Salem (GSO)',
     'Greenville (GLH)', 'Greenville (PGV)', 'Greenville/Spartanburg (GSP)', 'Grenada (GND)',
      'Grenoble (GNB)', 'Griffith (GFF)', 'Groningen (GRQ)', 'Groote Eylandt (GTE)', 
      'Groton/New London (GON)', 'Guadalajara (GDL)', 'Guadalcanal (GSI)', 'Guam (GUM)',
       'Guangzhou (CAN)', 'Sao Paulo (GRU)', 'Guatemala City (GUA)', 'Guayaquil (GYE)',
        'Guernsey (GCI)', 'Guettin (GTI)', 'Gulfport/Biloxi (GPT)', 'Guilin (KWL)', 'Gulu (ULU)',
         'Gunnison/Crested Butte (GUC)', 'Guwahati (GAU)', 'Gwadar (GWD)', 'Gweru (GWE)', 'Gympie (GYP)', 
         'Hachijo Jima (HAC)', 'Hagåtña (GUM)', 'Haifa (HFA)', 'Haines (HNS)', 'Hakodate (HKD)',
          'Halifax (YHZ)', 'Hall Beach (YUX)', 'Hamburg (HAM)', 'Hamilton (HLT)', 'Hamilton (YHM)', 
          'Hamilton (HLZ)', 'Hamilton Island (HTI)', 'Hammerfest (HFT)', 'Hancock (CMX)', 
          'Hangchow (HGH)', 'Hannover (HAJ)', 'Hanoi (HAN)', 'Harare (HRE)', 'Harbin (HRB)',
           'Harlingen/South Padre Island (HRL)', 'Harrington Harbour (YHR)', 'Harrisburg (HAR)', 
           'Harrisburg (MDT)', 'Hartford/Springfield (BDL)', 'Hatyai (HDY)', 'Haugesund (HAU)', 
           'Havana (HAV)', 'Havre (HVR)', 'Hayman Island (HIS)', 'Helena (HLN)', 'Helsingborg (JHE)',
            'Helsinki (HEL)', 'Heraklion (HER)', 'Hermosillo (HMO)', 'Hervey Bay (HVB)', 'Hibbing (HIB)', 
            'Hickory (HKY)', 'Hilo (ITO)', 'Hilton Head Island (HHH)', 'Hinchinbrook Island (HNK)', 
            'Hiroshima (HIJ)', 'Ho Chi Minh City (SGN)', 'Hobart (HBA)', 'Hof (HOQ)', 'Holguin (HOG)',
             'Home Hill (HMH)', 'Homer (HOM)', 'Hong Kong (HKG)', 'Hong Kong (ZJK)', 'Honiara (HIR)',
              'Honolulu (HNL)', 'Hoonah (HNH)', 'Horta (HOR)', 'Houston (HOU)', 'Houston (IAH)', 
              'Huahine (HUH)', 'Huatulco (HUX)', 'Hue (HUI)', 'Humberside (HUY)', 'Huntington (HTS)',
               'Huntsville (HSV)', 'Hurghada (HRG)', 'Huron (HON)', 'Hwange National Park (HWN)', 
               'Hyannis (HYA)', 'Hydaburg (HYG)', 'Hyderabad (HYD)', 'Hyderabad (HDD)', 'Ibiza (IBZ)',
                'Idaho Falls (IDA)', 'Iguazu (IGR)', 'Ile des Pins (ILP)', 'Ile Ouen (IOU)', 
                'Iliamna (ILI)', 'Imperial (IPL)', 'Invercargill (IVC)', 'Incheon (ICN)',
                 'Indianapolis (IND)', 'Ingham (IGH)', 'Innisfail (IFL)', 'Innsbruck (INN)',
                  'International Falls (INL)', 'Inuvik (YEV)', 'Inverness (INV)', 'Inykern (IYK)', 
                  'Iqaluit (YFB)', 'Iquitos (IQT)', 'Irkutsk (IKT)', 'Ishigaki (ISG)', 'Islamabad (ISB)', 
                  'Islay (ILY)', 'Isle of Man (IOM)', 'Istanbul (IST)', 'Istanbul (SAW)', 'Ithaca (ITH)', 
                  'Ivalo (IVL)', 'Ixtapa (ZIH)', 'Izmir (IZM)', 'Izmir (ADB)', 'Jackson Hole (JAC)',
                   'Jackson (JXN)', 'Jackson (MJQ)', 'Jackson (JAN)', 'Jackson (HKS)', 'Jackson (MKL)', 
                   'Jacksonville (LRF)', 'Jacksonville (NZC)', 'Jacksonville (NIP)', 'Jacksonville (JAX)',
                    'Jacksonville (CRG)', 'Jacksonville (IJX)', 'Jacksonville (OAJ)', 'Jacksonville (JKV)',
                     'Jacmel (JAK)', 'Jacobabad (JAG)', 'Jacobina (JCM)', 'Jacquinot Bay (JAQ)', 
                     'Jaffna (JAF)', 'Jagdalpur (JGB)', 'Jaipur (JAI)', 'Jaisalmer (JSA)', 'Jakarta (HLP)',
                      'Jakarta (JKT)', 'Jakarta (CGK)', 'Jalalabad (JAA)', 'Jalandhar (JLR)', 
                      'Jalapa (JAL)', 'Jales (JLS)', 'Jaluit Island (UIT)', 'Jamba (JMB)', 'Jambi (DJB)',
                       'Jambol (JAM)', 'Jamestown (JMS)', 'Jamestown (JHW)', 'Jammu (IXJ)',
     'Jamnagar (JGA)', 'Jamshedpur (IXW)', 'Janakpur (JKR)', 'Jandakot (JAD)', 'Janesville (JVL)', 
     'Januaria (JNA)', 'Jaque (JQE)', 'Jatai (JTI)', 'Jauja (JAU)', 'Jayapura (DJJ)', 'Jeddah (JED)', 
     'Jefferson City (JEF)', 'Jeremie (JEE)', 'Jerez de la Frontera (XRY)', 'Jersey (JER)', 
     'Jerusalem (JRS)', 'Jessore (JSR)', 'Jeypore (PYB)', 'Jian (JGS)', 'Jiamusi (JMU)', 
     'Jiayuguan (JGN)', 'Jijel (GJL)', 'Jijiga (JIJ)', 'Jilin (JIL)', 'Jimma (JIM)', 'Jinan (TNA)', 
     'Jingdezhen (JDZ)', 'Jinghong (JHG)', 'Jining (JNG)', 'Jinja (JIN)', 'Jinjiang (JJN)', 'Jinka (BCO)', 
     'Jinzhou (JNZ)', 'Jipijapa (JIP)', 'Jiri (JIR)', 'Jiujiang (JIU)', 'Jiwani (JIW)', 'Joacaba (JCB)',
      'Joao Pessoa (JPA)', 'Jodhpur (JDH)', 'Jönköping (JKG)', 'Joensuu (JOE)', 'Johannesburg (JNB)', 
      'Johnson City (BGM)', 'Johnston Island (JON)', 'Johnstown (JST)', 'Johor Bahru (JHB)',
       'Joinville (JOI)', 'Jolo (JOL)', 'Jomsom (JMO)', 'Jonesboro (JBR)', 'Joplin (JLN)', 'Jorhat (JRH)',
        'Jos (JOS)', 'Jose De San Martin (JSM)', 'Jouf (AJF)', 'Juanjui (JJI)', 'Juba (JUB)', 'Juist (JUI)',
         'Juiz De Fora (JDF)', 'Jujuy (JUJ)', 'Julia Creek (JCK)', 'Juliaca (JUL)', 'Jumla (JUM)', 
         'Jundah (JUN)', 'Juneau (JNU)', 'Junin (JNI)', 'Juticalpa (JUT)', 'Jwaneng (JWA)', 
'Jyväskylä (JYV)', 'Kabul (KBL)', 'Kagoshima (KOJ)', 'Kahramanmaras (KCM)', 'Kahului (OGG)', 
'Kajaani (KAJ)', 'Kalamata (KLX)', 'Kalamazoo (AZO)', 'Kalgoorlie (KGI)', 'Kaliningrad (KGD)',
 'Kalispell (FCA)', 'Kalmar (KLR)', 'Kamloops (YKA)', 'Kamuela (MUE)', 'Kano (KAN)', 'Kanpur (KNU)',
  'Kansas City (MCI)', 'Kaohsiung (KHH)', 'Kapalua (JHM)', 'Karachi (KHI)', 'Karlsruhe-Baden (FKB)',
   'Karlstad (KSD)', 'Karpathos (AOK)', 'Karratha (KTA)', 'Kars (KYS)', 'Karumba (KRB)', 'Karup (KRP)',
    'Kaschechawan (ZKE)', 'Kassala (KSL)', 'Katherine (KTR)', 'Kathmandu (KTM)', 'Katima Mulilo (MPA)',
     'Kauhajoki (KHJ)', 'Kaunakakai (MKK)', 'Kavalla (KVA)', 'Kayseri (ASR)', 'Kazan (KZN)', 
     'Keetmanshoop (KMP)', 'Kelowna (YLW)', 'Kemi (KEM)', 'Kenai (ENA)', 'Kent (MSE)', 'Kerry (KIR)', 
     'Ketchikan (KTN)', 'Key West (EYW)', 'Khamis Mushayat (AHB)', 'Kharga (UVL)', 'Kharkov (HRK)', 
     'Khartoum (KRT)', 'Khuzdar (KDD)', 'Kiel (KEL)', 'Kiev (KBP)', 'Kiev (IEV)', 'Kigali (KGL)', 
     'Kilimanjaro (JRO)', 'Killeen (ILE)', 'Kimberley (KIM)', 'King Island (KNS)', 'King Salomon (AKN)',
      'Kingscote (KGC)', 'Kingston (KIN)', 'Kingston (ISO)', 'Kingstown (SVD)', 'Kinshasa (FIH)', 
      'Kiritimati (CXI)', 'Kirkenes (KKN)', 'Kirkuk (KIK)', 'Kirkwall (KOI)', 'Kiruna (KRN)', 
    'Kisangani (FKI)', 'Kittilä (KTT)', 'Kitwe (KIW)', 'Klagenfurt (KLU)', 'Klamath Fall (OR) (LMT)', 
    'Klawock (AK) (KLW)', 'Kleinsee (KLZ)', 'Knock (NOC)', 'Knoxville (TN) (TYS)', 'Kobe (UKB)', 
    'Kochi (KCZ)', 'Köln, Köln/Bonn (CGN)', 'Kodiak (AK) (ADQ)', 'Kohat (OHT)', 'Kokkola/Pietarsaari (KOK)', 
    'Kolkata (Calcutta) - Netaji Subhas Chandra (CCU)', 'Komatsu (KMQ)', 'Kona (HI) (KOA)', 'Konya (KYA)', 
    'Korhogo (HGO)', 'Kos (KGS)', 'Kota Kinabalu (BKI)', 'Kotzbue (AK) (OTZ)', 'Kowanyama (KWM)', 
    'Krakow (Cracow) - John Paul II International Airport (KRK)', 'Kristiansand (KRS)', 'Kristianstad (KID',
         'Kristiansund (KSU)', 'Kuala Lumpur - International Airport (KUL)', 'Kuala Lumpur - Sultan Abdul Aziz Shah (SZB)', 
         'Kuantan (KUA)', 'Kuching (KCH)', 'Kumamoto (KMJ)', 'Kununurra (KNX)', 'Kuopio (KUO)', 'Kushiro (KUH)',
          'Kuujjuaq (FortChimo) (YVP)', 'Kuujjuarapik (YGW)', 'Kuusamo (KAO)', 'Kuwait - Kuwait International (KWI)',
           'Kyoto (UKY)', 'Labe (LEK)', 'Labouchere Bay (AK) (WLB)', 'Labuan (LBU)', 'Lac Brochet, MB (XLB)', 'La Coruna (LCG)', 'La Crosse (WI) (LSE)', 'Lae - Lae Nadzab Airport (LAE)', 
           'La Rochelle (LRH)', 'Lafayette (IN) (LAF)', 'Lafayette, La (LFT)', 'Lagos - Murtala Muhammed Airport (LOS)', 'La Grande (YGL)', 'Lahore (LHE)', 'Lake Charles (LA) (LCH)',
            'Lake Havasu City (AZ) (HII)', 'Lake Tahoe (CA) (TVL)', 'Lakselv (LKL)', 'Lambarene (LBQ)', 'Lamezia Terme (SUF)', 'Lampedusa (LMP)', 'Lanai City (HI) (LNY)',
             'Lancaster (PA) (LNS)', 'Lands End (LEQ)', 'Langkawi (islands) (LGK)', 'Lannion (LAI)', 'Lanseria (HLA)', 'Lansing (MI) (LAN)', 'La Paz - El Alto (LPB)',
              'La Paz - Leon (LAP)', 'Lappeenranta (LPP)', 'Laramie (WY) (LAR)', 'Laredo (TX) (LRD)', 'Larnaca (LCA)', 'Las Palmas (LPA)', 'Las Vegas (NV) (LAS)', 'Latrobe (PA) (LBE)', 'Launceston (LST)', 'Laurel/Hattisburg (MS) (PIB)', 'Laverton (LVO)', 'Lawton (OK) (LAW)', 'Lazaro Cardenas (LZC)', 'Leaf Rapids (YLR)', 'Learmouth (Exmouth) (LEA)', 'Lebanon (NH) (LEB)', 'Leeds/Bradford (LBA)', 'Leinster (LER)', 'Leipzig (LEJ)', 'Lelystad (LEY)', 'Leon (BJX)', 'Leonora (LNO)', 'Lerwick/Tingwall (Shetland Islands) (LWK)', 'Lewiston (ID) (LWS)', 
              'Lewistown (MT) (LWT)', 'Lexington (KY) (LEX)', 'Libreville (LBV)', 'Lidkoeping (LDK)', 'Liege (LGG)', 'Lifou (LIF)', 'Lihue (LIH)', 'Lille - Lille Airport (LIL)', 'Lilongwe - Lilongwe International (LLW)', 'Lima - J Chavez International (LIM)', 'Limassol (QLI)', 'Limoges (LIG)', 'Lincoln (NE) (LNK)', 'Lindeman Island (LDC)', 'Linz - Hoersching (LNZ)', 'Lisala (LIQ)', 'Lisbon - Lisboa (LIS)', 'Lismore (LSY)', 'Little Rock (AR) (LIT)', 'Liverpool (LPL)', 
              'Lizard Island (LZR)', 'Ljubljana - Brnik (LJU)', 'Lockhart River (IRG)', 'Lome (LFW)', 'London (YXU)', 'London Metropolitan Area (LON)', 'London - City Airport (LCY)', 'London - Gatwick (LGW)', 'London - Heathrow (LHR)', 'London - Luton (LTN)', 'London - Stansted (STN)', 'Londonderry - Eglinton (LDY)', 'Long Beach (CA) (LGB)', 'Long Island (AK) (LIJ)', 'Long Island, Islip (NY) - Mac Arthur (ISP)', 'Longreach (LRE)', 'Longview/Kilgore (TX) (GGG)',
               'Longyearbyen - Svalbard (LYR)', 'Loreto (LTO)', 'Lorient (LRT)', 'Los Angeles (CA) - International (LAX)', 'Los Cabos (SJD)', 'Los Mochis (LMM)', 'Los Rodeos (TFN)', 'Losinj - Losinj Arpt (LSZ)', 'Lourdes/Tarbes (LDE)', 'Louisville (KY) (SDF)', 'Luanda - 4 de Fevereiro (LAD)', 'Lubbock (TX) (LBB)', 'Lucknow (LKO)', 'Luederitz (LUD)', 'Luga (MLA)', 'Lugano (LUG)', 'Lulea (LLA)', 'Lumbumbashi (FBM)', 'Lusaka - Lusaka International Airport (LUN)', 
               'Lusisiki (LUJ)', 'Luxembourg (LUX)', 'Luxi - Mangshi (LUM)', 'Luxor (LXR)', 'Lvov (Lwow, Lemberg) (LWO)', 'Lydd - Lydd International Airport (LYX)', 'Lynchburg (VA) (LYH)', 'Lyon - Lyon-Saint Exupéry Airport (LYS)', 'Lyons (KS) - Rice County Municipal (LYO)', 'Maastricht/Aachen (MST)', 'Macapa - Alberto Alcolumbre International Airport (MCP)', 'Macau - Macau International Airport (MFM)', 'Maceio - Zumbi dos Palmares International Airport (MCZ)', 
               'Mackay (MKY)', 'Macon (GA) (MCN)', 'Mactan Island - Nab (NOP)', 'Madinah (Medina) - Mohammad Bin Abdulaziz (MED)', 'Madison (WI) - Dane County Regional Airport (MSN)', 'Madras (Chennai) - Chennai International Airport (MAA)', 'Madrid - Barajas Airport (MAD)', 'Mahe - Seychelles International (SEZ)', 'Mahon (MAH)', 'Maitland (MTL)', 'Majunga (MJN)', 'Makung (MZG)', 
               'Malabo - Malabo International Airport (SSG)', 'Malaga (AGP)', 'Malatya (MLX)', 'Male - Velana International Airport (MLE)', 'Malindi (MYD)', 'Malmo (Malmoe) (MMA)', 'Malmo (Malmö) - Malmö Airport (MMX)', 'Man (MJC)', 'Managua - Augusto C Sandino (MGA)', 'Manaus - Eduardo Gomes International Airport (MAO)', 'Manchester (MAN)', 'Manchester (NH) (MHT)', 'Mandalay - Mandalay-Annisaton Airport (MDL)', 'Manguna (MFO)', 'Manihi (XMH)', 'Manila - Ninoy Aquino International (MNL)', 'Manzanillo (ZLO)', 'Manzini - Matsapha International (MTS)', 'Maputo - Maputo International (MPM)', 'Mar del Plata (MDQ)', 'Maracaibo - La Chinita International (MAR)', 'Maradi (MFQ)', 'Maras (KCM)', 'Marathon (FL) (MTH)', 'Mardin (MQM)', 'Mare (MEE)',
                'Margate (MGH)', 'Margerita (PMV)', 'Maribor (MBX)', 'Mariehamn (Maarianhamina) (MHQ)', 'Maroua (MVR)', 'Marquette (MI) (MQT)', 'Marrakesh - Menara Airport (RAK)', 'Marsa Alam (RMF)', 'Marsa Matrah (Marsa Matruh) (MUH)', 'Marseille - Marseille Provence Airport (MRS)', 
                'Marsh Harbour (MHH)', 'Marthas Vineyard (MA) (MVY)', 'Martinsburg (WV) (MRB)', 'Maryborough (MBH)', 'Maseru - Moshoeshoe International (MSU)', 'Mason City (IA) (MCW)', 'Masvingo (MVZ)', 'Matsumoto, Nagano (MMJ)', 'Matsuyama (MYJ)', 'Mattoon (IL) (MTO)', 'Maun (MUB)', 'Maupiti (MAU)', 'Mauritius - S.Seewoosagur Ram International (MRU)', 'Mayaguez (MAZ)', 'Mazatlan (MZT)', 'McAllen (TX) (MFE)', 'Medan - Polania Intl (now Soewondo AFB) (MES)', 'Medan - Kualanamu International (KNO)', 'Medellin - José María Córdova International (MDE)', 'Medford (OR) (MFR)', 'Medina (MED)', 'Meekatharra (MKR)', 'Melbourne - Melbourne Airport (Tullamarine) (MEL)', 'Melbourne (FL) (MLB)', 'Melville Hall (DOM)', 'Memphis (TN) (MEM)', 
                'Mendoza (MDZ)', 'Manado (MDC)', 'Merced (CA) (MCE)', 'Merida (MID)', 'Meridian (MS) (MEI)', 'Merimbula (MIM)', 'Messina (MEZ)', 'Metlakatla (AK) (MTM)', 'Metz - Frescaty (MZM)', 'Metz/Nancy Metz-Nancy-Lorraine (ETZ)', 'Mexicali (MXL)', 'Mexico City - Mexico City International Airport (MEX)', 'Mexico City - Atizapan (AZP)', 'Mexico City - Juarez International (MEX)', 'Mexico City - Santa Lucia (NLU)', 'Mfuwe (MFU)', 'Miami (FL) (MIA)', 'Mianwali (MWD)', 
                'Middlemount (MMM)', 'Midland/Odessa (TX) (MAF)', 'Midway Island - Sand Island Field (MDY)', 'Mikkeli (MIK)', 'Milan (MIL)', 'Milan - Linate (LIN)', 'Milan - Malpensa (MXP)', 'Milan - Orio Al Serio (BGY)', 'Mildura (MQL)', 'Miles City (MT) (MLS)', 'Milford Sound (MFN)', 
                'Milwaukee (WI) (MKE)', 'Minatitlan (MTT)', 'Mineralnye Vody (MRV)', 'Minneapolis - St. Paul International Airport (MN) (MSP)', 'Minot (ND) (MOT)', 'Minsk, International (MSQ)', 'Miri (MYY)', 'Mirpur (QML)', 'Missoula (MT) (MSO)', 'Mitchell (SD) (MHE)', 'Miyako Jima (Ryuku Islands) - Hirara (MMY)', 'Miyazaki (KMI)', 'Mkambati (MBM)', 'Moanda (MFF)', 'Mobile (AL) - Pascagoula (MS) (MOB)', 'Modesto (CA) (MOD)', 'Moenjodaro (MJD)', 'Mogadishu (MGQ)', 
                'Mokuti (OKU)', 'Moline/Quad Cities (IL) (MLI)', 'Mombasa - Moi International (MBA)', 'Monastir (MIR)', 'Moncton (YQM)', 'Monroe (LA) (MLU)', 'Monrovia - Metropolitan Area (MLW)', 'Monrovia - Roberts International (ROB)', 'Montego Bay - Sangster International (MBJ)', 
                'Montenegro (QGF)', 'Monterey (CA) (MRY)', 'Monterrey - Gen. Mariano Escobedo (MTY)', 'Monterrey - Aeropuerto Del Norte (NTR)', 'Montevideo - Carrasco International Airport (MVD)', 'Montgomery (AL) - Montgomery Regional Airport (MGM)', 'Montpellier - Montpellier–Méditerranée Airport (MPL)', 'Montreal (YMQ)', 'Montreal - Dorval (Montréal-Trudeau) (YUL)', 'Montreal - Mirabel (YMX)', 'Montrose/Tellruide (CO) (MTJ)', 
                'Moorea (MOZ)', 'Moranbah (MOV)', 'Moree (MRZ)', 'Morelia (MLM)', 'Morgantown (WV) (MGW)', 'Morioka, Hanamaki (HNA)', 'Moroni - Prince Said Ibrahim (HAH)', 'Moruya (MYA)', 'Moscow - Metropolitan Area (MOW)', 'Moscow - Domodedovo (DME)', 'Moscow - Sheremetyevo (SVO)', 'Moscow - Vnukovo (VKO)', 'Moses Lake (WA) (MWH)', 'Mossel Bay (MZY)', 'Mostar (OMO)', 'Mosul (OSM)', 'Mouila (MJL)', 'Moundou (MQQ)', 'Mount Cook (GTN)', 'Mount Gambier (MGB)', 
                'Mount Magnet (MMG)', 'Mt. Isa (ISA)', 'Mt. McKinley (AK) (MCL)', 'Muenchen (Munich) - Franz Josef Strauss (MUC)', 'Muenster/Osnabrueck (FMO)', 'Mulhouse (MLH)', 'Multan (MUX)', 'Murcia (MJV)', 'Murmansk (MMK)', 'Mus (MSR)', 'Muscat - Seeb (MCT)', 'Muscle Shoals (AL) (MSL)', 
                'Muskegon (MI) (MKG)', 'Muzaffarabad (MFG)', 'Mvengue (MVB)', 'Mykonos (JMK)', 'Myrtle Beach (SC) - Myrtle Beach AFB (MYR)', 'Myrtle Beach (SC) - Grand Strand Airport (CRE)', 'Mysore (MYQ)', 'Mytilene (Lesbos) (MJT)', 'Mzamba (MZF)', 'Nadi (NAN)', 'Nagasaki (NGS)', 'Nagoya - Komaki AFB (NGO)', 'Nagpur (NAG)', 'Nairobi (NBO)', 'Nakhichevan (NAJ)', 'Nakhon Si Thammarat (NST)', 'Nancy (ENC)', 'Nanisivik (YSR)', 'Nanning (NNG)', 'Nantes - Nantes Atlantique Airport (NTE)', 'Nantucket (MA) (ACK)', 'Naples - Naples Capodichino Airport (NAP)', 'Naples (FL) (APF)', 'Narrabri (NAA)', 'Narrandera (NRA)', 'Narsarsuaq (UAK)', 'Nashville (TN) (BNA)', 'Nassau (NAS)', 'Natal - Augusto Severo International Airport (NAT)', 'Nausori (SUV)', 'Nawab Shah (WNS)', 'Naxos - Naxos Airport (JNX)', 'NDjamena (NDJ)', 'NDola (NLA)', 
                'Nelson (NSN)', 'Nelspruit (NLP)', 'Nelspruit - Kruger Mpumalanga International Airport (MQP)', 'Nevis (NEV)', 'New Bern (NC) (EWN)', 'New Haven (CT) (HVN)', 'New Orleans, La (MSY)', 'Newquay (NQY)', 'New Valley - Kharga (UVL)', 'New York - John F. Kennedy (NY) (JFK)', 'New York - LaGuardia (NY) (LGA)', 'New York - Newark (NJ) (EWR)', 'New York (NY) (NYC)', 'Newburgh (NY) (SWF)', 'Newcastle - Belmont (BEO)', 'Newcastle - Williamtown (NTL)', 
                'Newcastle (NCL)', 'Newcastle (NCS)', 'Newman (ZNE)', 'Newport News/Williamsburg (VA) (PHF)', 'NGaoundere (NGE)', 'Niagara Falls International (IAG)', 'Niamey (NIM)', 'Nice - Cote DAzur Airport (NCE)', 'Nicosia (NIC)', 'Nikolaev (NLV)', 'Niigata (KIJ)', 'Nimes (FNI)', 'Nis (INI)', 'Nizhny Novgorod - Strigino International Airport (GOJ)', 'Nome (OME)', 'Noosa (NSA)', 'Norfolk Island (NLK)', 'Norfolk/Virginia Beach (ORF)', 'Norman Wells (YVQ)', 
                'Norrkoeping (NRK)', 'North Bend (OTH)', 'North Eleuthera (ELH)', 'Norwich (NWI)', 'Nottingham - East Midlands (EMA)', 'Nouadhibou (NDB)', 'Nouakchott (NKC)', 'Noumea (NOU)', 'Novi Sad (QND)', 'Novosibirsk - Tolmachevo Airport (OVB)', 'Nürnberg (NUE)', 'Nuevo Laredo (NLD)', 'Nukualofa - FuaAmotu International (TBU)', 'Oakland (OAK)', 'Oaxaca - Xoxocotlan (OAX)', 'Odense (ODE)', 'Odessa (ODS)', 'Oerebro (ORB)', 'Ohrid (OHD)', 'Oita (OIT)', 
                'Okayama (OKJ)', 'Okinawa, Ryukyo Island - Naha (OKA)', 'Oklahoma City - Will Rogers World (OKC)', 'Olbia (OLB)', 'Olympic Dam (OLP)', 'Omaha (OMA)', 'Ondangwa (OND)', 'Ontario (ONT)', 'Oran (ORN)', 'Orange (OAG)', 'Orange County (Santa Ana) (SNA)', 'Oranjemund (OMD)', 'Oranjestad - Reina Beatrix International (AUA)', 'Orkney - Kirkwall (KOI)', 'Orlando Metropolitan Area (ORL)', 'Orlando - International Airport (MCO)', 'Orpheus Island (ORS)', 'Osaka, Metropolitan Area (OSA)', 'Osaka - Itami (ITM)', 'Osaka - Kansai International Airport (KIX)', 'Oshkosh (OSH)', 'Osijek (OSI)', 'Oslo - Oslo Airport, Gardermoen (OSL)', 
                'Oslo - Fornebu (FBU)', 'Oslo - Sandefjord (TRF)', 'Ottawa - Hull (YOW)', 'Ouadda (ODA)', 'Ouarzazate (OZZ)', 'Oudtshoorn (OUH)', 'Ouagadougou (OUA)', 'Oujda (OUD)', 'Oulu (OUL)', 'Out Skerries (Shetland) (OUK)', 'Oviedo (OVD)', 'Owensboro (OWB)', 'Oxnard (OXR)', 'Oyem (UVE)', 'Paderborn/Lippstadt (PAD)', 'Paducah (PAH)', 'Page/Lake Powell (PGA)', 'Pago Pago (PPG)', 'Parkersburg/Marietta (PKB)', 'Palermo - Punta Raisi (PMO)', 'Palma de Mallorca (PMI)', 'Palmas (PMW)', 'Palmdale/Lancaster (PMD)', 'Palmerston North (PMR)', 'Palm Springs (PSP)', 'Panama City - Tocumen International (PTY)', 'Panama City (FL) (PFN)', 'Panjgur (PJG)', 
                'Pantelleria (PNL)', 'Papeete - Faaa (PPT)', 'Paphos (PFO)', 'Paraburdoo (PBO)', 'Paramaribo - Zanderij International (PBM)', 'Paris (PAR)', 'Paris - Charles de Gaulle (CDG)', 'Paris - Le Bourget (LBG)', 'Paris - Orly (ORY)', 'Paro (PBH)', 'Pasco (PSC)', 'Pasni (PSI)', 'Patna (PAT)', 'Pattaya (PYX)', 'Pau (PUF)', 'Pellston (PLN)', 'Penang International (PEN)', 
                'Pendelton (PDT)', 'Pensacola (PNS)', 'Peoria/Bloomington (PIA)', 'Pereira (PEI)', 'Perpignan (PGF)', 'Perth International (PER)', 'Perugia (PEG)', 'Pescara (PSR)', 'Peshawar (PEW)', 'Petersburg (PSG)', 'Phalaborwa (PHW)', 'Philadelphia (PHL)', 'Phnom Penh (PNH)', 'Phoenix (PHX)', 'Phuket (HKT)', 'Pierre (PIR)', 'Pietermaritzburg (PZB)', 'Pietersburg (PTG)', 'Pilanesberg/Sun City (NTY)', 'Pisa (PSA)', 'Pittsburgh (PIT)', 'Plattsburgh (PLB)', 'Plettenberg Bay (PBZ)', 'Pocatello (PIH)', 'Podgorica (TGD)', 'Pohnpei (PNI)', 'Pointe a Pitre (PTP)', 'Pointe Noire (PNR)', 'Poitiers (PIS)', 'Ponce (PSE)', 'Ponta Delgada (PDL)', 
                'Pori (POR)', 'Port Angeles (CLM)', 'Port au Prince (PAP)', 'Port Augusta (PUG)', 'Port Elizabeth (PLZ)', 'Port Gentil (POG)', 'Port Harcourt (PHC)', 'Port Hedland (PHE)', 'Portland (PTJ)', 'Portland (PWM)', 'Portland (PDX)', 'Port Lincoln (PLO)', 'Port Macquarie (PQQ)', 'Port Menier (YPN)', 'Port Moresby (POM)', 'Porto (OPO)', 'Porto Alegre (POA)', 
                'Port of Spain (POS)', 'Port Said (PSD)', 'Porto Santo (PXO)', 'Porto Velho (PVH)', 'Port Vila (VLI)', 'Poughkeepsie (POU)', 'Poznan (POZ)', 'Prague (PRG)', 'Praia (RAI)', 'Presque Island (PQI)', 'Pretoria (PRY)', 'Preveza/Lefkas (PVK)', 'Prince George (YXS)', 'Prince Rupert (YPR)', 'Pristina (PRN)', 'Prosperpine (PPP)', 'Providence (PVD)', 'Prudhoe Bay (SCC)', 'Puebla (PBC)', 'Pueblo (PUB)', 'Puerto Escondido (PXM)', 'Puerto Ordaz (PZO)', 'Puerto Plata (POP)', 'Puerto Vallarta (PVR)', 'Pukatawagan (XPK)', 'Pula (PUY)', 'Pullman (PUW)', 
                'Pune (PNQ)', 'Punta Arenas (PUQ)', 'Punta Cana (PUJ)', 'Pu San (PUS)', 'Pyongyang (FNJ)', 'Quebec (YQB)', 'Queenstown (UEE)', 'Queenstown (ZQN)', 'Quetta (UET)', 'Qingdao (TAO)', 'Quimper (UIP)', 'Quincy (UIN)', 'Quito (UIO)', 'Rabat (RBA)', 'Rahim Yar Khan (RYK)', 'Raiatea (RFP)', 'Rainbow Lake (YOP)', 'Rajkot (RAJ)', 'Raleigh/Durham (RDU)', 'Ranchi (IXR)', 'Rangiroa (RGI)', 'Rangoon (RGN)', 'Rapid City (RAP)', 'Rarotonga (RAR)', 'Ras al Khaymah (RKT)', 'Rawala Kot (RAZ)', 'Rawalpindi (RWP)', 'Reading (RDG)', 'Recife (REC)', 'Redding (RDD)', 'Redmond (RDM)', 'Reggio Calabria (REG)', 'Regina (YQR)', 'Reina Sofia (TFS)', 
                'Rennes (RNS)', 'Reno (RNO)', 'Resolute Bay (YRB)', 'Reus (REU)', 'Reykjavik - Metropolitan Area (REK)', 'Reykjavik - Keflavik International (KEF)', 'Rhinelander (RHI)', 'Rhodos (RHO)', 'Richards Bay (RCB)', 'Richmond (RIC)', 'Riga (RIX)', 'Rijeka (RJK)', 'Rimini (RMI)', 'Rio Branco - Plácido de Castro International Airport (RBR)', 'Rio de Janeiro - Galeao (GIG)', 'Rio de Janeiro - Santos Dumont (SDU)', 'Rio de Janeiro (RIO)', 'Riyadh - King Khaled International (RUH)', 'Roanne (RNE)', 'Roanoke (ROA)', 'Roatan (RTB)', 'Rochester (RST)', 
                'Rochester (ROC)', 'Rock Sound (RSD)', 'Rock Springs (RKS)', 'Rockford (RFD)', 'Rockhampton (ROK)', 'Rockland (RKD)', 'Rocky Mount - Wilson (RWI)', 'Rodez (RDZ)', 'Rodrigues Island (RRG)', 'Roenne (RNN)', 'Rome (ROM)', 'Rome - Ciampino (CIA)', 'Rome - Fuimicino (FCO)', 'Ronneby (RNB)', 'Rosario (ROS)', 'Rostov-on-Don - Rostov-on-Don Airport (RVI)', 'Rostov-on-Don - Platov International Airport (ROV)', 'Rotorua (ROT)', 'Rotterdam (RTM)', 'Rovaniemi (RVN)', 'Rundu (NDU)', 'Ruse (ROU)', 'Saarbruecken (SCN)', 'Sacramento (SMF)', 
                'Sado Shima (SDS)', 'Saginaw/Bay City/Midland (MBS)', 'Saidu Sharif (SDT)', 'Saigon (SGN)', 'Saint Brieuc (SBK)', 'Saint Denis - Roland Garros Airport (RUN)', 'Saint John (YSJ)', 'Saipan (SPN)', 'Sal (SID)', 'Salalah (SLL)', 'Salem (SLE)', 'Salinas (SNS)', 'Salinas (SNC)', 'Salisbury (SAY)', 'Salisbury (SBY)', 'Saloniki (SKG)', 'Salta, Gen Belgrano (SLA)', 'Salt Lake City (SLC)', 'Salvador - Salvador International Airport (SSA)', 'Salzburg - W.A. Mozart (SZG)', 'Samara - Kurumoch International Airport (KUF)', 'Samarkand - Samarkand International Airport (SKD)', 'Samos (SMI)', 'Samsun (SZF)', 'San Andres (ADZ)', 'San Angelo (SJT)', 'San Antonio (SAT)', 'San Carlos de Bariloche (BRC)', 'San Diego - Lindberg Field International (SAN)', 'San Francisco - International Airport (SFO)', 'San Jose Cabo (SJD)', 
                'San Jose (SJO)', 'San Jose (SJC)', 'San Juan - Luis Munoz Marin International Airport (SJU)', 'San Luis Obisco (SBP)', 'San Luis Potosi (SLP)', 'San Pedro (SPY)', 'San Pedro Sula (SAP)', 'San Salvador (ZSA)', 'San Salvador (SAL)', 'San Sebastian (EAS)', 'Sanaa - Sanaa International (SAH)', 'Sandspit (YZP)', 'Santa Ana - John Wayne Airport (SNA)', 'Santa Barbara (SBA)', 'Santa Cruz de la Palma (SPC)', 'Santa Cruz de la Sierra (SRZ)', 'Santa Katarina - Mount Sinai (SKV)', 'Santa Maria (SMA)', 'Santa Maria (SMX)', 'Santander (SDR)', 
                'Santa Rosa (STS)', 'Santa Rosa (SRB)', 'Santa Rosa (SRA)', 'Santa Rosa (RSA)', 'Santa Rosa, Copan (SDH)', 'Santa Rosalia (SSL)', 'Santa Rosalia (SRL)', 'Santiago - Antonio Maceo Airport (SCU)', 'Santiago de Chile - Arturo Merino Benitez (SCL)', 'Santiago de Compostela (SCQ)', 'Santo (SON)', 'Santo Domingo (SDQ)', 'Sao Luis - Marechal Cunha Machado International (SLZ)', 'Sao Paulo (SAO)', 'Sao Paulo - Congonhas (CGH)', 'Sao Paulo - Guarulhos (GRU)', 'Sao Paulo - Viracopos (VCP)', 'Sao Tome (TMS)', 'Sapporo (SPK)', 'Sapporo - Okadama (OKD)', 'Sapporo - New Chitose Airport (CTS)', 'Sarajevo (SJJ)', 'Saransk - Saransk Airport (SKX)', 'Sarasota/Bradenton (SRQ)', 'Saskatoon (YXE)', 'Sassandra (ZSS)', 
                'Savannah (SAV)', 'Savonlinna (SVL)', 'Scarborough - Crown Point International (TAB)', 'Scone (NSO)', 'Scottsdale (SCF)', 'Seattle/Tacoma (SEA)', 'Sehba (SEB)', 'Seinaejoki (SJY)', 'Selibi Phikwe (PKW)', 'Sendai (SDJ)', 'Seoul - Incheon International Airport (ICN)', 'Seoul - Kimpo (SEL)', 'Sevilla (SVQ)', 'Sfax (SFA)', 'Shamattawa, MB (ZTM)', 'Shanghai - Hongqiao (SHA)', 'Shanghai - Pu Dong (PVG)', 'Shannon (SNN)', 'Sharjah (SHJ)', 'Sharm El Sheikh (SSH)', 'Sheffield, City Airport (SZD)', 'Sheffield, Doncaster, Robin Hood International Airport (DSA)', 'Shenandoah Valley/Stauton (SHD)', 'Shenyang (SHE)', 'Shenzhen - Shenzhen Baoan International (SZX)', 'Sheridan (SHR)', 'Shreveport (SHV)', 'Shute Harbour (JHQ)', 'Sibu (SBW)', 'Sidney (SDY)', 'Silistra (SLS)', 'Simferopol (SIP)', 'Sindhri (MPD)', 
                'Singapore - Changi (SIN)', 'Singapore - Paya Lebar (QPG)', 'Singapore - Seletar (XSP)', 'Singleton (SIX)', 'Sioux City IA (SUX)', 'Sioux Falls (FSD)', 'Sishen (SIS)', 'Sitka (SIT)', 'Sivas (VAS)', 'Siwa (SEW)', 'Skagway (SGY)', 'Skardu (KDU)', 'Skiathos (JSI)', 'Skopje (SKP)', 'Skrydstrup (SKS)', 'Skukuza (SZK)', 'Sligo (SXL)', 'Smithers (YYD)', 'Sodankylae (SOT)', 'Soenderborg (SGD)', 'Soendre Stroemfjord (SFJ)', 'Sofia - Vrazhdebna (SOF)', 'Sogndal (SOG)', 'Southampton - Eastleigh (SOU)', 'South Bend (SBN)', 'South Indian Lake, MB (XSI)', 'South Molle Island (SOI)', 'Southend (SEN)', 'Split (SPU)', 'Spokane (GEG)', 
                'Springbok (SBU)', 'Springfield (SPI)', 'Springfield (SGF)', 'Srinagar (SXR)', 'St. Augustin, PQ (YIF)', 'St. Croix (STX)', 'St. Etienne (EBU)', 'St. George (SGU)', 'St. Johns (YYT)', 'St. Kitts (SKB)', 'St Louis LambertSt. Louis International Airport (STL)', 'St. Lucia Hewanorra (UVF)', 'St. Lucia Vigle (SLU)', 'St. Marteen (SXM)', 'St. Martin (SFG)', 'St. Petersburg (LED)', 'St. Pierre, NF (FSP)', 'St. Thomas (STT)', 'St. Vincent (SVD)', 'Stansted (STN)', 'State College/Belefonte (SCE)', 'Stavanger (SVG)', 'Steamboat Springs (HDN)', 'Stettin (SZZ)', 'Stockholm Metropolitan Area (STO)', 'Stockholm - Arlanda (ARN)', 
                'Stockholm - Bromma (BMA)', 'Stockton (SCK)', 'Stornway (SYY)', 'Strasbourg - Strasbourg Airport (SXB)', 'Streaky Bay (KBY)', 'Stuttgart - Echterdingen (STR)', 'Sui (SUL)', 'Sukkur (SKZ)', 'Sumburgh (LSI)', 'Sun Valley (SUN)', 'Sundsvall (SDL)', 'Sunshine Coast (MCY)', 'Surabaya - Juanda (SUB)', 'Surat (STV)', 'Suva - Nausori Airport (SUV)', 'Swakopmund (SWP)', 'Sydney - Sydney Airport (SYD)', 'Sylhet (ZYL)', 'Syracuse (SYR)', 'Tabuk (TUU)', 'Taif (TIF)', 'Taipei - Chiang Kai Shek (TPE)', 'Taipei - Sung Shan (TAY)', 'Taiyuan (TYN)', 'Takamatsu (TAK)', 'Talkeetna (TKA)', 'Tallahassee (TLH)', 'Tallinn - Pirita Harbour (QUF)', 'Tallinn - Ulemiste (TLL)', 'Tampa - International (TPA)', 'Tampere (TMP)', 'Tampico - Gen. F. Javier Mina (TAM)', 'Tamworth (TMW)', 'Tangier - Boukhalef (TNG)', 'Taree (TRO)', 
                'Targovishte (TGV)', 'Tashkent - International (TAS)', 'Tawau (TWU)', 'Tbilisi - Novo Alexeyevka (TBS)', 'Te Anau (TEU)', 'Teesside, Durham Tees Valley (MME)', 'Tegucigalpa (TGU)', 'Tehran - Mehrabad (THR)', 'Tekirdag - Corlu (TEQ)', 'Tel Aviv - Ben Gurion International (TLV)', 'Telluride (TEX)', 'Temora (TEM)', 'Tenerife (TCI)', 'Tenerife - Sur Reina Sofia (TFS)', 'Tenerife - Norte Los Rodeos (TFN)', 'Tennant Creek (TCA)', 'Terceira (TER)', 'Teresina (THE)', 'Termez (TMZ)', 'Terrace (YXT)', 'Terre Haute (HUF)', 'Texarkana (TXK)', 
                'ThabaNchu (TCU)', 'The Pas (YQD)', 'Thessaloniki - Makedonia Apt. (SKG)', 'Thief River Falls (TVF)', 'Thira (JTR)', 'Thiruvananthapuram (TRV)', 'Thisted (TED)', 'Thompson (YTH)', 'Thorne Bay (KTB)', 'Thunder Bay (YQT)', 'Thursday Island (TIS)', 'Tianjin (TSN)', 'Tijuana - Rodriguez (TIJ)', 'Tioman (TOD)', 'Tirana - Rinas (TIA)', 'Tiruchirapally (TRZ)', 'Tivat (TIV)', 'Tobago (TAB)', 'Tokushima (TKS)', 'Tokyo (TYO)', 'Tokyo - Haneda (HND)', 'Tokyo - Narita (NRT)', 'Toledo (TOL)', 'Tom Price (TPR)', 'Toowoomba (TWB)', 'Toronto - Billy Bishop Toronto City Airport (YTZ)', 'Toronto - Toronto Pearson International Airport (YYZ)', 
                'Toronto (YTO)', 'Tortola (TOV)', 'Touho (TOU)', 'Toulouse - Blagnac Airport (TLS)', 'Townsville (TSV)', 'Toyama (TOY)', 'Trabzon (TZX)', 'Trapani (TPS)', 'Traverse City (TVC)', 'Treasure Cay (TCB)', 'Trenton/Princeton (TTN)', 'Treviso (TSF)', 'Tri-Cities Regional (TRI)', 'Trieste (TRS)', 'Tripoli - Tripoli International (TIP)', 'Tromsoe (TOS)', 'Trondheim (TRD)', 'Tsumeb (TSB)', 'Tucson (TUS)', 'Tulepo (TUP)', 'Tulsa (TUL)', 'Tunis - Carthage (TUN)', 'Turbat (TUK)', 'Turin (TRN)', 'Turku (TKU)', 'Tuscaloosa (TCL)', 'Tuxtla Gutierrez (TGZ)', 'Twin Falls (TWF)', 'Tyler (TYR)', 'Ua Huka (UAH)', 'Ua Pou (UAP)', 'Ube (UBJ)', 
                'Uberaba (UBA)', 'Uberlandia - Eduardo Gomes Airport (UDI)', 'Ubon Ratchathani - Muang Ubon Airport (UBP)', 'Udaipur - Dabok Airport (UDR)', 'Uden - Volkel Airport (UDE)', 'Udon Thani (UTH)', 'Ufa (UFA)', 'Uherske Hradiste (UHE)', 'Uige (UGO)', 'Ujung Pandang - Hasanudin Airport (UPG)', 'Ukhta (UCT)', 'Ukiah (UKI)', 'Ulaanbaatar - Buyant Uhaa Airport (ULN)', 
                'Ulan-Ude (UUD)', 'Ulanhot (HLH)', 'Ulei (ULB)', 'Ulsan (USN)', 'Ulundi (ULD)', 'Umea (UME)', 'Umiujaq (YUD)', 'Umtata (UTT)', 'Unalakleet (UNK)', 'Union Island (UNI)', 'Unst (Shetland Island) - Baltasound Airport (UNT)', 'Upala (UPL)', 'Upernavik - Upernavik Heliport (JUV)', 'Upington (UTN)', 'Upolu Point (UPP)', 'Uranium City (YBE)', 'Urgench (UGC)', 'Uriman (URM)', 'Urmiehm (OMH)', 'Uruapan (UPN)', 'Urubupunga - Ernesto Pochler Airport (URB)', 'Uruguaiana - Ruben Berta Airport (URG)', 'Urumqi (URC)', 'Uruzgan (URZ)', 'Ushuaia - Islas Malvinas Airport (USH)', 'Utapao (Pattaya) (UTP)', 'Utica (NY) - Oneida County Airport (UCA)', 'Utila (UII)', 'Uummannaq (UMD)', 'Uzhgorod (UDJ)', 'Vaasa (VAA)', 'Vaexjoe (VXO)', 'Vail (EGE)', 'Val dOr (YVO)', 'Valdez (VDZ)', 'Valdosta (VLD)', 'Valencia (VLC)', 
                'Valencia (VLN)', 'Valladolid (VLL)', 'Valparaiso (VAP)', 'Valverde (VDE)', 'Van - Ferit Melen (VAN)', 'Vancouver - Vancouver International (YVR)', 'Varadero (VRA)', 'Varanasi (VNS)', 'Varkaus (VRK)', 'Varna (VAR)', 'Vasteras (VST)', 'Velikiye Luki (VLU)', 'Venice - Marco Polo (VCE)', 'Veracruz (VER)', 'Vernal (VEL)', 'Vero Beach/Ft. Pierce (VRB)', 'Verona (Brescia) Montichiari (VBS)', 'Verona (VRN)', 'Victoria (YYJ)', 'Victoria Falls (VFA)', 
                'Vidin (VID)', 'Vientiane - Wattay (VTE)', 'Vigo (VGO)', 'Villahermosa (VSA)', 'Vilnius (VNO)', 'Virgin Gorda (VIJ)', 'Visalia (VIS)', 'Visby (VBY)', 'Vitoria (VIT)', 'Vitoria - Eurico de Aguiar Salles Airport (VIX)', 'Vryheid (VYD)', 'Wabush (YWK)', 'Waco (ACT)', 'Wagga (WGA)', 'Walla Walla (ALW)', 'Wallis (WLS)', 'Walvis Bay (WVB)', 'Warrnambool (WMB)', 'Warsaw - Frédéric Chopin (WAW)', 'Washington DC - Baltimore Washington International (BWI)', 
                'Washington DC - Dulles International (IAD)', 'Washington DC - Ronald Reagan National (DCA)', 'Washington DC (WAS)', 'Waterloo IA (ALO)', 'Watertown (ATY)', 'Wausau/Stevens Point (CWA)', 'Weipa (WEI)', 'Welkom (WEL)', 'Wellington (WLG)', 'Wenatchee (EAT)', 'West Palm Beach (PBI)', 'West Yellowstone (WYS)', 'Westerland, Sylt Airport (GWT)', 'Whakatane (WHK)', 'Whale Cove (YXN)', 'Whangarei (WRE)', 'White Plains (HPN)', 'Whitehorse (YXY)', 'Whitsunday Resort (HAP)', 'Whyalla (WYA)', 'Wichita Falls (SPS)', 'Wichita (ICT)', 'Wick (WIC)', 
                'Wickham (WHM)', 'Wien (VIE)', 'Wiesbaden, Air Base (WIE)', 'Wilkes Barre/Scranton (AVP)', 'Williamsport (IPT)', 'Williston (ISL)', 'Wilmington (ILM)', 'Wilna (VNO)', 'Wiluna (WUN)', 'Windhoek - Eros (ERS)', 'Windhoek - Hosea Kutako International (WDH)', 'Windsor Ontario (YQG)', 'Winnipeg International (YWG)', 'Wolf Point (OLF)', 'Wollongong (WOL)', 'Woomera (UMR)', 'Worcester (ORH)', 'Worland (WRL)', 'Wrangell (WRG)', 'Wuhan (WUH)', 'Wyndham (WYN)', 'Xiamen (XMN)', 'Xian - Xianyang (XIY)', 'Yakima (YKM)', 'Yakutat (YAK)', 'Yakutsk (YKS)', 'Yamagata, Junmachi (GAJ)', 'Yamoussoukro (ASK)', 'Yanbu (YNB)', 'Yangon (RGN)', 'Yaounde (YAO)', 'Yellowknife (YZF)', 'Yekaterinburg - Koltsovo Airport (SVX)', 'Yichang (YIH)', 'Yokohama (YOK)', 'Yuma (YUM)', 'Zacatecas (ZCL)', 'Zadar (ZAD)', 'Zagreb - Zagreb Airport Pleso (ZAG)', 'Zakynthos (ZTH)', 'Zaragoza (ZAZ)', 'Zhob (PZH)', 'Zinder (ZND)', 'Zouerate (OUZ)', 'Zurich - Kloten (ZRH)',
]


export default airportData;